import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { apiRoot, usd, validateEmail } from '../services/helpers';
import axios from 'axios';

const PwResetForm = ( props:any ) => {
    const { match } = props;

    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( -1 );
    const [formData, setFormData] = useState({
        password: '',
    });
    const [showPw, setShowPw] = useState( false );

    const params:any = useParams() || { hash: '0000'};
    const pwHash = params.hash;

    const submitForm = async () => {
        setSubmitting(true);
        let tmpData:any = { ...formData };
        tmpData.reset_hash = pwHash;

        try {
            const res = await axios.post( apiRoot()+'/auth/pwresetsubmit', tmpData );

            if (res.data.success) {
                setSuccess(1);
            } else {

            }
        } catch(err) {
            setSubmitting(false);
        }
    }

    return (
        <div className='pf-login'>
            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            { success !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Reset Your Password</h1>
                    <p>Enter your new password below.<br/>Tap/Click the lock icon to show or hide your password. <br/>&nbsp;</p>

                    <div className="form-group">
                        <label>New Password:</label>
                        <div className="input-group">
                            <div className="input-group-prepend" onClick={()=>setShowPw(!showPw)} style={{cursor:'pointer'}}>
                                <div className="input-group-text"><i className={"far fa-lock" + (showPw ? '-open' : '')}></i></div>
                            </div>
                            <input type={showPw ? 'text' : 'password'} className="form-control" value={formData.password}
                                   placeholder=""
                                   onChange={(e: any) => setFormData({ ...formData, password: e.target.value })}/>
                        </div>
                    </div>

                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                            onClick={() => submitForm()}
                            disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Password Reset Successfully!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>Please continue to the login screen to sign in with your new password.</p>
                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                        Continue to Login
                    </Link>
                </div>
            }

        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(PwResetForm);

