import axios from 'axios';
import {
  USER_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE, CLEAR_MEMBER, CLEAR_PLANS, CLEAR_PAYMENTS,
} from '../types';

import { setAlert, removeAlert } from "./alert";
import { setAuthToken, clearAuthToken } from '../../utils/setAuthToken';
import { apiRoot } from '../../services/helpers';
import { localSave } from '../../utils/LocalStorage';


// LOAD USER
export const loadUser = () => async (dispatch:any) => {
  if (localStorage.auth_user) {

    const json = JSON.parse( localStorage.auth_user );
    const now = Math.floor(Date.now() / 1000);
    const expires = json.token_expires;

    if (expires > now) {
      setAuthToken(json.token);
      dispatch({type: USER_LOADED, payload: json});
    }
    else {
      localStorage.removeItem('auth_user');
    }
  }
};



// Login User
export const login = (email:string, password:string) => async (dispatch:any) => {
  const config = {
    headers: { 'Content-Type': 'application/json' }
  };
  const body = {
    email: email.trim(),
    password: password.trim(),
  };

  try {
    const res = await axios.post(apiRoot() + '/auth/token', body, config);

    if (res.data.success) {
      await localSave( 'auth_user', JSON.stringify(res.data.user) );
      setAuthToken(res.data.user.token);

      dispatch({ type: LOGIN_SUCCESS, payload: res.data.user });
      dispatch(removeAlert('signinError'));
    }

  } catch(err) {
    dispatch( setAlert( 'signinError', 'Invalid Username / Password', [],'danger') );
    dispatch({ type: LOGIN_FAIL });
  }
};



// Logout User / Clear Profile
export const logout = () => (dispatch:any) => {
  clearAuthToken();
  //dispatch({type: CLEAR_PROFILE});
  dispatch({type: LOGOUT});
  dispatch({type: CLEAR_MEMBER});
  dispatch({type: CLEAR_PROFILE});
  dispatch({type: CLEAR_PLANS});
  dispatch({type: CLEAR_PAYMENTS});
}



// Register User
export const register = ( data:any ) => async (dispatch:any) => {
  try {
    const res = await axios.post(apiRoot() + '/auth/user', data);

    if (res.data.success) {
      dispatch(removeAlert('registerError'));
      return res.data;
    } else {
      return res.data;
    }

  } catch(err) {
    //dispatch( setAlert( 'registerError', 'Error Registering', [],'danger') );
    return { success: false };
  }
};



// Change Password
export const updatePassword = ( data:any ) => async (dispatch:any) => {
  dispatch(removeAlert('setPwError'));
  try {
    const res = await axios.put(apiRoot() + '/auth/setpw', data);

    if (res.data.success) {
      return true;
    } else {
      dispatch( setAlert( 'setPwError', 'Error Updating Password', res.data.errors,'danger') );
      return false;
    }

  } catch(err) {
    dispatch( setAlert( 'setPwError', 'Error Updating Password', [],'danger') );
    return false;
  }
};
