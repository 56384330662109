import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { removeAlert } from '../redux/actions/alert';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../redux/actions/profile';
import { loadUser } from '../redux/actions/auth';

import Login from '../screens/auth/Login';
import MainWrapper from '../screens/layout/MainWrapper';
import Dashboard from '../screens/Dashboard';
import Member from '../screens/Member';
import { getPlans } from '../redux/actions/plans';
import Register from '../screens/auth/Register';
import MyAccount from '../screens/MyAccount';
import EmailList from '../screens/EmailList';
import PaymentsLog from '../screens/PaymentsLog';
import EmailCampaigns from '../screens/EmailCampaigns';
import Donate from '../screens/Donate';
import Newsletter from '../screens/Newsletter';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../services/helpers';
import { Elements } from '@stripe/react-stripe-js';
import PwReset from '../screens/PwReset';
import PwResetForm from '../screens/PwResetForm';

const Router = ( props: any ) => {

    const { removeAlert, auth, profile, plans, loadUser, getPlans } = props;

    const [authChecked, setAuthChecked] = useState( false );

    useEffect( () => {
        (async () => {
            if (!auth.isAuthenticated) await loadUser();
            setAuthChecked( true );
        })();
    }, [auth])

    useEffect( () => {
        (async () => {
            if (!plans.plansLoaded) await getPlans();
        })();
    }, [plans.plansLoaded])

    const stripePromise = loadStripe(stripeKey());
    const isAuthenticated = auth.isAuthenticated;

    return (
        <MainWrapper>
            {authChecked &&
              <Elements stripe={stripePromise}>
                  {isAuthenticated ?
                      <Routes>
                          <Route path="/member/:id" element={<Member/>}/>
                          <Route path="/dashboard" element={<Dashboard/>}/>
                          <Route path="/my-account" element={<MyAccount/>}/>
                          <Route path="/email-campaigns" element={<EmailCampaigns/>}/>
                          <Route path="/email-create" element={<EmailList/>}/>
                          <Route path="/payment-log" element={<PaymentsLog/>}/>
                          <Route path="/login" element={<Login/>}/>
                          <Route path="/" element={<Login/>}/>
                      </Routes>
                      :
                      <Routes>
                          <Route path="/dashboard" element={<Dashboard/>}/>
                          <Route path="/login" element={<Login/>}/>
                          <Route path="/register" element={<Register/>}/>
                          <Route path="/donate" element={<Donate/>}/>
                          <Route path="/newsletter" element={<Newsletter/>}/>
                          <Route path="/pwreset" element={<PwReset/>}/>
                          <Route path="/pwreset-form/:hash" element={<PwResetForm/>}/>
                          <Route path="/" element={<Login/>}/>
                      </Routes>
                  }
              </Elements>
            }
        </MainWrapper>
    );
}


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile,
    plans: state.plans,
});

export default connect(mapStateToProps, { getCurrentProfile, removeAlert, loadUser, getPlans })(Router);
