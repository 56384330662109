import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import {
    apiRoot,
    CARD_ELEMENT_OPTIONS,
    checkVotingDate,
    stripeKey, usd,
    validateEmail,
    yearApplied
} from '../services/helpers';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const Donate = (props: any) => {
    const {} = props;

    //RECAPTCHA
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const [submitting, setSubmitting] = useState( false );
    const [paySuccess, setPaySuccess] = useState( -1 );
    const [payError, setPayError] = useState( '' );
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        project: 'PHCDC General Fund',
        amount: '10.00',
    });

    const stripe = useStripe();
    const elements = useElements();

    const formValid = () => {
        let valid = true;
        if (!formData.name.length) {
            valid = false;  setPayError('Name is a required field.');
        } else if (!validateEmail(formData.email)) {
            valid = false;  setPayError('Please enter a valid email address.');
        } else if (!formData.phone.length) {
            valid = false;  setPayError('Phone Number is a required field.');
        } else if (!formData.project.length) {
            valid = false;  setPayError('Project is a required field.');
        } else if (parseFloat(formData.amount) < 5) {
            valid = false;  setPayError('Donations must be greater than $5.00');
        }
        if (!valid) {
            setSubmitting( false );
        }
        return valid;
    }

    const formDisabled = () => {

    }

    const getStripeToken = async () => {
        console.log(formData);
        if (!stripe || !elements) { return; }
        setSubmitting( true );
        setPayError('');

        const ok2pay = formValid();

        if (ok2pay) {
            const card:any = elements.getElement(CardElement);
            const result:any = await stripe.createToken(card);

            if (result.error) {
                // Show error to your customer.
                console.log(result.error.message);
            } else {
                // Send the token to your server.
                let emailReceipt = '';

                const metadata:any = {
                    Type: 'Donation',
                    Donor: formData.name,
                    Email: formData.email,
                    Phone: formData.phone,
                    Notes: ''
                }

                const paymentData = {
                    token: result.token.id,
                    amount: parseFloat(formData.amount)*100,
                    metadata: metadata,
                    email: formData.email,
                    description: 'Donation: $' + formData.amount + ' to benefit ' + formData.project
                };

                try {
                    const res:any = await axios.post( apiRoot()+'/payments/charge', paymentData);
                    console.log('payRes', res);
                    if (res.data.success) {
                        const data = { ...formData, metadata: res.data.chargeResponse };
                        console.log('data', data);
                        await axios.post(apiRoot()+'/payments/donation', data);
                        setSubmitting(false);
                        setPaySuccess( 1 );
                    }
                } catch(err) {
                    setSubmitting(false);
                    setRefreshReCaptcha(!refreshReCaptcha);
                    setPayError( 'There was an error processing this credit card.' );
                }

            }
        }
    }

    const setTokenFunc = (getToken:any) => {
        setToken(getToken);
    };

    return (
        <div className='pf-login'>
            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            {paySuccess !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Make a Donation</h1>

                    <div className="form-group">
                        <label>Your Name:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-user"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.name} placeholder="John Doe"
                                   onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email Address:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-envelope"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.email}
                                   placeholder="jdoe@example.com"
                                   onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Phone Number:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-phone"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.phone}
                                   placeholder="412-555-1212"
                                   onChange={(e: any) => setFormData({ ...formData, phone: e.target.value })}/>
                        </div>
                    </div>

                    <br/>
                    <div className="form-group">
                        <label>Donation Amount:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-dollar-sign"></i></div>
                            </div>
                            <input type="number" className="form-control" value={formData.amount} placeholder="0.00"
                                   onChange={(e: any) => setFormData({ ...formData, amount: e.target.value })}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Project to Support:</label>
                        <div className="input-group">
                            <select onChange={(e: any) => setFormData({ ...formData, project: e.target.value })}
                                    value={formData.project} className="form-control">
                                <optgroup label="Penn Hills CDC">
                                    <option value="PHCDC General Fund">PHCDC General Fund</option>
                                    <option value="PHCDC Community Census Fund">PHCDC Community Census Fund</option>
                                    <option value="PHCDC Beautification Fund">PHCDC Beautification Fund</option>
                                    <option value="PHCDC Anti-Litter Fund">PHCDC Anti-Litter Fund</option>
                                </optgroup>
                                <optgroup label="PHCDC Partners">
                                    <option value="Allegheny River Boulevard Preservation Assoc.">
                                        Allegheny River Boulevard Preservation Assoc.
                                    </option>
                                    <option value="Penn Hills Community Garden">Penn Hills Community
                                        Garden
                                    </option>
                                    <option value="Penn Hills Garden Club">Penn Hills Garden Club
                                    </option>
                                    <option value="Penn Hills Shade Tree Commission">Penn Hills
                                        Shade Tree Commission
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group">
                            <label>Credit Card</label>
                            <div className="form-control cc-wrap" style={{ paddingTop: '6px' }}>
                                <CardElement options={CARD_ELEMENT_OPTIONS}/>
                            </div>
                        </div>
                    </div>

                    <br/><br/>
                    {payError !== '' &&
                      <div className="alert alert-danger" style={{ fontSize: '16px', marginTop: '24px' }}>
                        <b>{payError}</b>
                      </div>
                    }
                    <p>By clicking the Submit Payment button, you authorize the Penn Hills CDC to charge your credit
                        card in the amount of <b>{usd(formData.amount)}</b>. Charges will appear on your
                        statement as PENNHILLSCDC. A recipt for your donation will be emailed to the address you
                        provided.</p>

                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '40px', paddingRight: '40px' }}
                            onClick={() => getStripeToken()}
                            //onClick={() => formDisabled()}
                            disabled={submitting}>&nbsp; &nbsp; Submit Donation &nbsp; &nbsp;</button>

                    <GoogleReCaptchaProvider reCaptchaKey={'6Lc4uqEqAAAAAO5dyjMKxq1JVNDD7iPisx97hcCc'}>
                        <GoogleReCaptcha
                            //className="google-recaptcha-custom-class"
                            onVerify={setTokenFunc}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                    </GoogleReCaptchaProvider>

                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Donation Successful!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>Your donation in the amount
                        of <b>{usd(formData.amount)}</b> supporting the <b>{formData.project}</b> has been successfully
                        sent to the Penn Hills CDC.
                        Please check your inbox for a receipt of this donation.</p>
                    <p style={{ fontSize: '16px' }}>Questions? Please contact the PHCDC Treasurer at <a
                        href="mailto:treasurer@pennhillscdc.org">treasurer@pennhillscdc.org</a></p>

                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>Back
                        to Home</Link>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Donate);

