import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiRoot, pct, ucwords } from '../services/helpers';

const EmailCampaigns = ( props:any ) => {
    const { } = props;

    const initArr:any = [];
    const [campaigns, setCampaigns] = useState( initArr );
    const [initCampaigns, setInitCampaigns] = useState( false );

    useEffect(() => {
        if (!initCampaigns) {
            (async () => {
                let res:any = await axios.get(apiRoot()+'/mail/campaigns');
                console.log('res', res);
                setCampaigns( res.data.data );
                setInitCampaigns( true );
            })();
        }
    }, [ initCampaigns ]);

    return (
        <div className="dashboard">
            <h3 className="title">
                Email Campaigns
            </h3>

            <div className="breadcrumb">
                <Link to={'/'}>Home</Link> &nbsp;&nbsp;/&nbsp;&nbsp; <Link to={'/dashboard'}>Members</Link> &nbsp;&nbsp;/&nbsp;&nbsp; Email Campaigns
            </div>

            <p>
                <Link to={'/email-create'} className="btn btn-success">
                    <i className="fas fa-plus-circle"></i>  &nbsp;
                    Create New Email Campaign
                </Link>
            </p>

            <div>
                <table className={'table table-striped'}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Subject</th>
                        <th>To Group</th>
                        <th>Day</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Emails Sent</th>
                        <th>Reads</th>
                        <th>Read %</th>
                        <th>Unsub'd</th>
                    </tr>
                    </thead>
                    <tbody>
                    { campaigns.map( (c:any) =>
                        <tr>
                            <td>{c.id}</td>
                            <td>{c.subject}</td>
                            <td>{ucwords(c.recipients)}</td>
                            <td>{c.day}</td>
                            <td>{c.date}</td>
                            <td>{c.time}</td>
                            <td style={{textAlign:'center'}}>{c.recipient_count}</td>
                            <td style={{textAlign:'center'}}>{c.read}</td>
                            <td style={{textAlign:'center'}}>{ pct(parseInt(c.read) / parseInt(c.recipient_count)) }%</td>
                            <td style={{textAlign:'center'}}>{c.unsubscribed}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(EmailCampaigns);

