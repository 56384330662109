import './scss/woodbed.scss';

import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routing/Router';
import ScrollToTop from './components/ScrollToTop';

// REDUX
import { Provider } from 'react-redux';
import store from './redux/store.js';

function App() {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop/>
          <Fragment>
            <Router/>
          </Fragment>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
