import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatPhone } from '../services/helpers';
import { CSVLink, CSVDownload } from "react-csv";
import { updateMember, createMember } from '../redux/actions/members';

const MemberTable = (props: any) => {
    const { membersArray, tableView, changeView, setNewMemberOpen, counts, updateMember, createMember } = props;

    const year = parseInt(moment().format('YYYY'));

    const csvExport = () => {

    }

    const hideMember = async ( m:any ) => {
        const yn = window.confirm("Are you sure you want to delete this "+m.fname+" "+m.lname+" from the members list?");
        if (yn) {
            m.maillist = 0;
            m.visible = 0;
            await updateMember( m );
        }
    }

    const setEmail = async ( m:any, newVal:number ) => {

        const yn = window.confirm(
            newVal === 0 ?
            "Are you sure you want to remove "+m.fname+" "+m.lname+" from the email list?"
                :
            "Are you sure you want to re-add "+m.fname+" "+m.lname+" to the email list?"
        );
        if (yn) {
            m.maillist = newVal;
            await updateMember( m );
        }
    }

    return (
        <div className="member-table">
            <div className="header">
                <h3>Members</h3>
                <div className={"member-btn-group"}>
                    <button className={"btn btn-link" + (tableView==='active'?' active' : '')} onClick={()=>changeView('active')}>Active ({counts.active})</button>
                    <button className={"btn btn-link" + (tableView==='expired'?' active' : '')} onClick={()=>changeView('expired')}>Expired ({counts.expired})</button>
                    <button className={"btn btn-link" + (tableView==='recruits'?' active' : '')} onClick={()=>changeView('recruits')}>Recruits ({counts.recruits})</button>
                    <button className={"btn btn-link" + (tableView==='all'?' active' : '')} onClick={()=>changeView('all')}>All ({counts.all})</button>
                </div>
                <div className="new-member-btn">
                    <button className={"btn btn-link new"} onClick={()=>setNewMemberOpen(true)}><i className="fas fa-plus-circle"></i> Member</button>
                    {/*<CSVLink data={membersArray} filename={"PHCDC-members-"+tableView+".csv"} className={"btn btn-link new"}>&nbsp;&nbsp;&nbsp;*/}
                    {/*    <i className="fas fa-arrow-alt-to-bottom"></i> CSV</CSVLink>*/}
                    <Link to={'/email-campaigns'} className={"btn btn-link new"} > &nbsp; <i className="fas fa-envelope"></i> Emails</Link>
                    <Link to={'/payment-log'} className={"btn btn-link new"} > &nbsp; <i className="fas fa-usd-circle"></i> Payments</Link>
                </div>
            </div>
            <table className="table table-hover table-striped">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th className={'hide-730'}>Email</th>
                    <th className={'hide-890'}>Phone</th>
                    <th className={'hide-1100'}>Address</th>
                    <th>Plan</th>
                    <th>Paid Thru</th>
                    <th className={'hide-475'}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {membersArray.map((m: any) =>
                    <tr key={m.member_id} className={( parseInt(m.paid_thru) < year ? 'expired' : '')}>
                        <td><Link to={'/member/'+m.member_id}>{m.member_id}</Link></td>
                        <td>{m.lname}, {m.fname}</td>
                        <td className={'hide-730'}><a href={"mailto:"+m.email}>{ m.email }</a></td>
                        <td className="text-nowrap hide-890"><a href={"tel:"+m.phone}>{ formatPhone(m.phone) }</a></td>
                        <td className={'hide-1100'}>{m.address1 + (m.address2.length ? ', ' + m.address2 : '')}</td>
                        <td>{m.plan_name}</td>
                        <td>{m.paid_thru}</td>
                        <td className={'hide-475'}>
                            <Link to={'/member/'+m.member_id}><i className="fas fa-edit fa-fw edit-member" style={{color:"#005ecc"}}></i></Link>
                            <i className="fas fa-fw fa-user-slash" style={{color:"#DD0000"}} onClick={()=>hideMember( m )}></i>
                            { parseInt(m.maillist) === 1 ?
                                <i className="fas fa-fw fa-bell-slash" style={{color:"#444444"}} onClick={()=>setEmail( m, 0 )}></i>
                                    :
                                <i className="fas fa-fw fa-bell" style={{color:"#009900"}} onClick={()=>setEmail( m, 1 )}></i>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, { updateMember, createMember })(MemberTable);

