import React, { PureComponent } from 'react';

interface MainWrapperProps {
    theme?: any;
    children: any;
}
class MainWrapper extends PureComponent<MainWrapperProps> {

    render() {
        const { children } = this.props;

        return (
            <div className='app-wrapper'>
                <header></header>
                <main>{children}</main>
                <footer></footer>
            </div>
        );
    }
}

export default MainWrapper;
